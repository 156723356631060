.App {
  background-image: url('../assest/inicio.png');
  background-size: cover;
  /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-position: center;
  /* Centra la imagen */
  background-repeat: no-repeat;
  /* Evita que la imagen se repita */
  max-width: 100%;
  height: 100vh;
  /* Asigna la altura del 100% del viewport */
  padding: 20px;
  text-align: center;
}


.App h1 {
  font-size: 35px;
  color: #efe9e9;
  margin-top: 10px;
}

.contenedor-silos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.silo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background-color: #b3b1b199;
  width:150px;
  border-radius: 30px;
}

.silo h3 {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  color: #000;
  margin-top: 10px;
}

.rueda {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #ccc;
}
.porcentaje-fill {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ff0000; /* Color de fondo */
  border-radius: 50%; /* Hace que el porcentaje se vea circular */
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0% 50%); /* Define un clip-path para crear el efecto de llenado */
  transform-origin: center; /* Ajusta el origen de la transformación */
  transform: rotate(0deg); /* Inicialmente sin rotación */
  transition: clip-path 0.5s ease; /* Agrega una transición para una animación suave del llenado */
}

.porcentaje {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.texto-porcentaje {
  font-size: 16px;
  font-weight: bold;
  color: #050404; /* Color del texto */
}




.fecha-hora {
  font-size: 25px;
  color: #e8dcdc;
  margin-top: 15px;

}

.mineral-patio {
  margin-top: 20px;
  padding: 5px;
  background-color: #16151578;
  border-radius: 8px;
}

.mineral-patio p {
  font-size: 18px;
  font-weight: bold;
  color: #dad0d0;
}

.mineral-patio ul {
  list-style-type: none;
  padding: 0;
}

.mineral-patio li {
  margin-bottom: 5px;
  font-size: 40px;
  color: #dad0d0;
}


@media screen and (max-width: 768px) {


  .silo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    background-color: #b3b1b199;
    width:90px;
    
    border-radius: 30px;
  }
  .silo h3 {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    color: #000;
    margin-top: 10px;
  }
  .rueda {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 5px solid #ccc;
  }

  .porcentaje-fill {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ff0000; /* Color de fondo */
    border-radius: 50%; /* Hace que el porcentaje se vea circular */
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0% 50%); /* Define un clip-path para crear el efecto de llenado */
    transform-origin: center; /* Ajusta el origen de la transformación */
    transform: rotate(0deg); /* Inicialmente sin rotación */
    transition: clip-path 0.5s ease; /* Agrega una transición para una animación suave del llenado */
  }

  .App h1 {
    font-size: 22px;
    color: #efe9e9;
    margin-top: 5px;
  }

  .fecha-hora {
    font-size: 18px;
    color: #e8dcdc;
    margin-top: 5px;

  }

  .mineral-patio {
    margin-top: 5px;
    padding: 5px;
    background-color: #16151578;
    border-radius: 8px;
  }

  .mineral-patio p {
    font-size: 12px;
    font-weight: bold;
    color: #dad0d0;
  }

  .mineral-patio ul {
    list-style-type: none;
    padding: 0;
  }

  .mineral-patio li {
    margin-bottom: 5px;
    font-size: 20px;
    color: #dad0d0;
    font-weight: bold;
  }
}