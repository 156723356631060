.molienda-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Centra verticalmente los elementos */
    height: 100vh;
    margin-left: 12px;

}

.buttonmo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }

  .iconnnesmo {
    /* Estilos para el icono */
 position: absolute; /* Establece la posición absoluta para el icono */
 top: 40%; /* Lo posiciona en la mitad superior del botón */
 left: 5px; /* Lo posiciona 5px desde el borde izquierdo del botón */
 transform: translateY(-50%); /* Centra verticalmente el icono */
 font-size: 3em; /* Tamaño del icono relativo al tamaño del botón */
  }
  .iconnnespdf {
    /* Estilos para el icono */
 position: absolute; /* Establece la posición absoluta para el icono */
 top: 40%; /* Lo posiciona en la mitad superior del botón */
 left: 15px; /* Lo posiciona 5px desde el borde izquierdo del botón */
 transform: translateY(-50%); /* Centra verticalmente el icono */
 font-size: 2em; /* Tamaño del icono relativo al tamaño del botón */
  }
  .buttonmo-text {
    font-size: 18px;
    margin-left:10px;
    font-family: 'Verdana', sans-serif;
  }
  .buttonmo-textss {
    font-size: 12px;
    margin-left:10px;
    font-family: 'Verdana', sans-serif;
  }
  .btnmo-large {
    padding: 30px 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
   
  }
.btnmo{
  padding: 20px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
   
}
  .btnmo-large:hover {
    background-color: #000000e8;
    color: #f6f6f6;
  }

  .btnmo-pt {
    background-color: #124bc4fd;
    color: #ffe1e1;
  }

  .btnmo-pp {
    background-color: #e91c1c;
    color: #f5dbdb;
  }
  
  .btnmo-bs {
    background-color: #ff0000;
    color: #040404;
  }
  