.chart {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 80%;
  margin: 20px auto;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9; /* Fondo claro para destacar las barras */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil para dar profundidad */
  border-radius: 10px; /* Bordes redondeados */
}

.bar {
  width: 20px;
  background-color: #007bff;
  margin-bottom: 2px; /* Reducir la separación entre las barras */
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
  transition: height 0.3s ease, background-color 0.3s ease; /* Transición suave también para el color de fondo */
  border-radius: 4px 4px 0 0; /* Bordes superiores redondeados */
}

.bar:hover {
  background-color: #0056b3;
}

.label {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  color: #666;
  font-family: Arial, sans-serif; /* Asegurarse de que la fuente sea consistente */
}



/* Button Styles */
.custom-buttonmesas {
  background-color: #ca3737; /* Green background */
  color: rgb(241, 223, 223); /* White text */
  border: none; /* Remove border */
  padding: 10px 32px; /* Add padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get buttons inline */
  font-size: 15px; /* Increase font size */
  margin: 10px 2px; /* Add some margin */
  cursor: pointer; /* Add pointer cursor on hover */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.custom-buttonmolinos {
  background-color: #4463bf; /* Green background */
  color: rgb(241, 231, 231); /* White text */
  border: none; /* Remove border */
  padding: 10px 32px; /* Add padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get buttons inline */
  font-size: 15px; /* Increase font size */
  margin: 10px 2px; /* Add some margin */
  cursor: pointer; /* Add pointer cursor on hover */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}
.custom-buttonchinos {
  background-color: #437750; /* Green background */
  color: rgb(241, 231, 231); /* White text */
  border: none; /* Remove border */
  padding: 10px 32px; /* Add padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get buttons inline */
  font-size: 15px; /* Increase font size */
  margin: 10px 2px; /* Add some margin */
  cursor: pointer; /* Add pointer cursor on hover */
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.custom-buttonmesas:hover {
  background-color: #0e0f0f; /* Darker green on hover */
}
.custom-buttonmolinos:hover {
  background-color: #0e0f0f; /* Darker green on hover */
}
.custom-buttonchinos:hover {
  background-color: #0e0f0f; /* Darker green on hover */
}