.login-section {
    background-image: url('../../assest/mina\ portada.jpg ');
    background-size: cover;
    /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
    background-position: center;
    /* Centra la imagen */
    height: 100vh;
    /* Asigna la altura del 100% del viewport */
    display: flex;
    justify-content: center;
    align-items: center;

}

.error {
    display: flex;
    color: #fff;
    font-size: 18px;
    justify-content: center;
}

.containerlogin {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    width: 600px;
    background-color: #1a19196c;
    padding-bottom: 0px;
    box-sizing: border-box;


}

.headerlogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 30px;
}

.textlogin {
    color: #000000;
    font-size: 48px;
    font-weight: 700;
}

.underlinelogin {
    width: 61px;
    height: 6px;
    background: #050505;
    border-radius: 9px;
}

.inputlogin {
    display: flex;
    align-items: center;
    margin: auto;
    width: 450px;
    height: 70px;
    background: #fffcfc;
    border-radius: 6px;

}
.olvcontra{
 background-color: #007bff00; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  border: none; /* Sin borde */
  padding: 10px 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cursor al pasar el ratón */
  transition: background-color 0.3s ease; /* Transición suave del color de fondo */
}

.olvcontra:hover {
    background-color: #0057b300;
    color: #eedf12; /* Color de fondo del botón al pasar el ratón */
  }
.inputlogin input {
    height: 40px;
    width: 450px;
    background: transparent;
    border: none;
    outline: none;
    color: #020202;
    font-size: 19px;
}

.input img {
    margin: 0px 30px;
}

.forgot-password {
    padding-left: 5px;
    
    color: #070606;
    font-size: 18px;

}

.forgot-password span {
    color: #e60c0c;
    cursor: pointer;

}

.submit-container {
    display: flex;
    
    margin: 60px 5px;
    justify-content: center;
}



.submits {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background: #050505;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 300;
    cursor: pointer;


}

.pop {
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media screen and (min-width: 768px) {
    .containerlogin {
        margin-top: 100px;
        /* Ajusta el margen superior para pantallas grandes */
    }
}

/* Media query para pantallas pequeñas */
@media screen and (max-width: 767px) {
 

    .inputlogin {
        display: flex;
        align-items: center;
        margin: auto;
        width: 250px; /* Cambiado a ancho automático para adaptarse al contenido */
        height: 70px;
        background: #fffcfc;
        border-radius: 6px;
    }

    .inputlogin input {
        height: 40px;
        width: 200px;
        background: transparent;
        border: none;
        outline: none;
        color: #020202;
        font-size: 19px;
    }
}