
.form-container {
    display: flex;
    justify-content: center;
    margin: 5% auto; /* Margen superior e inferior del 5%, centrado horizontalmente */
    max-width: 500px; /* Ancho máximo del contenedor */
    background-color: black;
    border-radius: 10px;
  }
  
  .form-container h3 {
    margin-bottom: 15px;
    color: #feeded;
  }
  
  .form-container label {
    margin-bottom: 5px;
    color: #fff;
  }
  
  .form-container .form-control {
    width: 155%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-container button {
    background-color: #ec0b0b;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  