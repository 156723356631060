.existencia-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centra verticalmente los elementos */
    height: 100vh; 
    margin-left: 15px;
}

  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  .iconnnes {
     /* Estilos para el icono */
  position: absolute; /* Establece la posición absoluta para el icono */
  top: 40%; /* Lo posiciona en la mitad superior del botón */
  left: 5px; /* Lo posiciona 5px desde el borde izquierdo del botón */
  transform: translateY(-50%); /* Centra verticalmente el icono */
  font-size: 4em; /* Tamaño del icono relativo al tamaño del botón */
  
   
   
  }
  .button-text {
    font-size: 18px;
    margin-left:25px;
    font-family: 'Verdana', sans-serif;
  }
  .button-textss {
    font-size: 12px;
    margin-left:25px;
    font-family: 'Verdana', sans-serif;
  }
  
  .btn-large {
    padding: 30px 50px;
    display: flex;
   
    align-items: center;
    flex-direction: column;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    background-color: #ed0959fd;
    color: #ffffff;
  }
  
  .btn-pt {
    background-color: #ed0909fd;
    color: #ffffff;
  }
  
  .btn-pp {
    background-color: #15359c;
    color: #ffffff;
  }
  
  .btn-mp {
    background-color: #22611c;
    color: #ffffff;
  }
  .btn-nota {
    background-color: #d3390e;
    color: #ffffff;
  }
  .btn-bs {
    background-color: #121241;
    color: #ffffff;
  }
  
  .btn-large:hover {
    background-color: #0f0e0e;
  }
  
 