/* Common styles */
.columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  overflow-x: auto;
}

.JIGS input {
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid #141313;
  border-radius: 4px;
}

.JIGS1 {
  margin-bottom: 2px;
}

.mmm {
  display: flex;
  justify-content: space-between;
}

.mmm input {
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid #bd3131;
  border-radius: 4px;
  margin-bottom: 5px;
}

.columna {
  margin-right: 10px;
}

.btn-container {
  margin-top: -5px;
}

.btn-containerjs {
  margin-top: -50px;
  margin-left: 63%;
}

.BTN {
  background-color: #ff0000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

form {
  display: flex;
  flex-direction: column;
}

input::placeholder {
  color: #100d0d;
  font-family: 'TuTipoDeLetra', sans-serif;
  font-size: 14px;
}

label {
  display: block;
  font-size: 13px;
  font-weight: bold;
  color: #f80505;
}

.columna input {
  width: auto;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid #251f8c;
  border-radius: 4px;
  margin-bottom: 5px;
}

.medios {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.mmm select {
  width: 100%;
  padding: 2px;
  box-sizing: border-box;
  border: 1px solid #bd3131;
  border-radius: 4px;
}

.close-button {
  position: fixed;
  top: 10px;
  right: 18%;
  cursor: pointer;
  color: #333;
  font-size: 1.5rem;
}

.close-button:hover {
  color: red;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .btn-containerjs {
    margin-left: 50%;
  }
  h1{
    margin-left: 5px;
  }
}

@media screen and (max-width: 576px) {
  .close-button {
    right: 10px;
  }
}
