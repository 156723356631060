/* Estilos generales */
.registrar-section {
    padding: 20px;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerregistro {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px; /* Ajusta el margen superior */
    width: 90%; /* Utiliza un ancho relativo */
    max-width: 500px; /* Establece un ancho máximo */
    background-color: #92929298;
    padding: 20px;
    box-sizing: border-box;
    
}

.headerregistro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px; /* Ajusta el margen superior */
}

.textregistro {
    color: #020101da;
    font-size: 36px; /* Reducción del tamaño de fuente para pantallas pequeñas */
    font-weight: 700;
}

.underlineregistro {
    width: 50px; /* Reducción del ancho */
    height: 4px; /* Reducción de la altura */
    background: #151212;
    border-radius: 6px; /* Reducción del radio de borde */
}

.forgot-registrar {
    padding-left: 5px;
    color: #000000;
    font-size: 16px; /* Reducción del tamaño de fuente para pantallas pequeñas */
}

.inputregistro {
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%; /* Utiliza un ancho relativo */
    max-width: 450px; /* Establece un ancho máximo */
    height: 50px; /* Reducción de la altura */
    background: #fffcfc;
    border-radius: 6px;
}

.inputregistro input {
    flex: 1;
    height: 100%;
    width: auto;
    padding: 0 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #020202;
    font-size: 16px; /* Reducción del tamaño de fuente para pantallas pequeñas */
}

.submit-registrar {
    display: flex;
    margin: 30px auto; /* Ajuste del margen */
    justify-content: center;
    align-items: center;
}

.registrarse {
    width: 100%; /* Utiliza el ancho completo */
    max-width: 220px;
    height: 50px; /* Reducción de la altura */
    color: #fff;
    background: #040404;
    border-radius: 25px; /* Mitad del alto */
    font-size: 18px; /* Reducción del tamaño de fuente para pantallas pequeñas */
    font-weight: 300;
    cursor: pointer;
}

/* Media query para pantallas pequeñas */
@media screen and (max-width: 767px) {
    .textregistro {
        font-size: 28px; /* Reducción adicional del tamaño de fuente */
    }
}
