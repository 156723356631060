*{
  margin:0;
  padding: 0;

  

 
}

.containermenu{
  display: flex;
  
  
  
  
}

.main{
  width: 100%;
  transform: translateX(-10px);
  height: 100% 

}
.menu{
  background: #000;
  color:#fff;
  height: 100vh;
  width: 300px;
  transition: all 0.5s;
 
}
.top_section{
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.logo{
  font-size: 30px;
}

.bars{
  display: flex;
  margin-left: 100px;
  font-size: 30px;
}

.link{
  display: flex;
  color: #fff;
  padding: 10px 10px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.link:hover{
  background:  rgb(231, 43, 43) ;
  color: #000;
  transition: all 0.5s;
}
.active{
  background: rgb(231, 43, 43) ;
  color:#000;

}

.icon, .link_text{
  font-size: 22px;

}

