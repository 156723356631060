
.linea{
    width: 60px;
    height: 5px;
    background: #f80101;
    border-radius: 9px;
}
.texto{
    color: #f80505;
    font-size: 35px;
    font-weight: 500;
}

.headert{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    width: 100%;
    margin-top: 10px;
}

.BTN{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 40px;
    color: #ffefef;
    background: #af0707;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;
    font-weight: bold;
    gap: 5px;
  }

 .btn-container{
    display: flex;
    margin: 10px auto;
    justify-content: center;
 }

 Label{
    font-weight: bold;
    
 }

 .silos input{
    width:100%;
    padding:5px;
    box-sizing: border-box;
    border: 1px solid #00426e;
    border-radius: 4px;
    margin-bottom: 2px;
 }
 #otrassalidas{
    width: 100%;
 }