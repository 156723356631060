/* Estilos para el contenedor principal */
.modales {
    
    position: flex;
    margin-top: 5%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  /* Estilos para el formulario */
  .modales form {
    background-color: rgba(13, 13, 13, 0.971);
    padding: 20px;
    width: 300px;
    height:200px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Estilos para las etiquetas del formulario */
  .modales form label {
    display: block;
    margin-bottom: 10px;
    color: #ccc;
  }
  
  /* Estilos para los campos de entrada */
  .modales form input[type="password"] {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Estilos para el botón de enviar */
  .modales form button {
    padding: 10px 20px;
    background-color: #ff1500; /* Color azul */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Estilos para el botón de enviar al pasar el ratón */
  .modales form button:hover {
    background-color: #0056b3; /* Color azul oscuro */
  }
  h2{
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:5%;
  }