.diario-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centra verticalmente los elementos */
    height: 100vh; 
    margin-left: 15px;
}
.exportaciones {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .btnmo-be {
    background-color: #ed0909fd;
    color: #ffffff;
  }
  .btnmo-bo {
    background-color: #3ca926fd;
    color: #ffffff;
  }
  .btne {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    
   
  }

  .iconnnesex {
    /* Estilos para el icono */
 position: absolute; /* Establece la posición absoluta para el icono */
 top: 40%; /* Lo posiciona en la mitad superior del botón */
 left: 3px; /* Lo posiciona 5px desde el borde izquierdo del botón */
 transform: translateY(-50%); /* Centra verticalmente el icono */
 font-size: 2em; /* Tamaño del icono relativo al tamaño del botón */
 
  
  
 }